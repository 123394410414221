<template>
  <div ref="chartdiv" :style="`height: ${height}px`" class="chart card"></div>
</template>

<style scoped>
.chart {
  width: 100%;
  height: 500px;
}
</style>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import eventBus from "@/core/services/event/eventBus.js"

export default {
  props: {
    selectedCountry: {
      type: String,
      default: "",
    },
    highlightedCountries: {
      type: Array,
      default: () => [],
    },
    height: {
      type: [Number, String],
      default: 500,
    },
    lines: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      /**@type am5.Root */
      root: null,
      /** @type am5map.MapPolygonSeries */
      worldSeries: null,
      selected_country_risk_level: null,
    };
  },

  computed: {
    mapPolygonsLength() {
      if (!this.worldSeries) return;

      return this.worldSeries.mapPolygons.length;
    },
  },

  watch: {
    selectedCountry(val) {
      if (!val || !this.root || !this.worldSeries) return;
      this.zoomToCountry(val);
      this.colorCountries(val);
    },

    mapPolygonsLength: {
      deep: true,
      handler(val) {
        if (!val) return;
        this.zoomToCountry(this.selectedCountry);
        this.colorCountries(this.selectedCountry);
      },
    },
  },

  mounted() {
    let root = am5.Root.new(this.$refs.chartdiv);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        minZoomLevel: 1,
        maxZoomLevel: 3,
        pinchZoom: true,
        projection: am5map.geoNaturalEarth1(),
        wheelY: 'none',
        panX: 'rotateX'
      }),
    );

    chart.set("zoomControl", am5map.ZoomControl.new(root, {}));
    let overlay = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layer: 100,
        visible: false,
      }),
    );

    overlay.children.push(
      am5.Rectangle.new(root, {
        width: am5.p100,
        height: am5.p100,
        fill: am5.color(0x000000),
        fillOpacity: 0.3,
      }),
    );

    overlay.children.push(
      am5.Label.new(root, {
        text: "Use CTRL + Scroll to zoom",
        fontSize: 30,
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
      }),
    );

    chart.events.on("wheel", function (ev) {
      if (ev.originalEvent.ctrlKey) {
        ev.originalEvent.preventDefault();
        chart.set("wheelY", "zoom");
      } else {
        chart.set("wheelY", "none");
        overlay.show();
        overlay.setTimeout(function () {
          overlay.hide();
        }, 800);
      }
    });

    let worldDataCopy = JSON.parse(JSON.stringify(am5geodata_worldLow));  // Creating a deep copy

    worldDataCopy.features.forEach(feature => {
      let countryName = feature.properties.name;
      feature.properties.name = this.$t(countryName);
    });

    let worldSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: worldDataCopy,
        exclude: ["AQ"],
      }),
    );

    worldSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      fill: am5.color("#e0e0e0"),
      templateField: "columnSettings",
      strokeWidth: 0.3,
      stroke: am5.color("#3b3b3b"),
      fillPattern: am5.LinePattern.new(root, {
        color: am5.color(0xffffff),
        width: 800,
        height: 800,
        colorOpacity: 0.9,
        rotation: 45,
        strokeWidth: 0.5
      }),
    });

    worldSeries.mapPolygons.template.states.create("1", {
      fill: am5.color("#16a085"),
      fillPattern: null
    });

    worldSeries.mapPolygons.template.states.create("2", {
      fill: am5.color("#f1c40f"),
      fillPattern: null
    });

    worldSeries.mapPolygons.template.states.create("3", {
      fill: am5.color("#f39c12"),
      fillPattern: null
    });

    worldSeries.mapPolygons.template.states.create("4", {
      fill: am5.color("#c0392b"),
      fillPattern: null
    });

    worldSeries.mapPolygons.template.states.create("selected", {
      fill: am5.color("#b2b2b2"),
      fillPattern: null
    });

    worldSeries.mapPolygons.template.states.create("highlighted", {
      fill: am5.color("#c9c9c9"),
      fillPattern: null
    });

    worldSeries.mapPolygons.template.events.on("click", ev => {
      this.selected_country_risk_level = null;
      this.$emit("click", ev.target.dataItem.dataContext.id);
    });

    this.worldSeries = worldSeries;

    this.root = root;

    this.$parent.$parent.$on('update-color-in-map', (risk_level) => {
      // Handle the received event on the parent component
      this.selected_country_risk_level = risk_level;
      this.colorCountries(this.selectedCountry);
    });

    this.colorCountries();

    eventBus.$on('update-color-map', this.colorCountries)
  },

  beforeDestroy() {
    if (this.worldSeries) {
      this.worldSeries.dispose();
    }

    if (this.root) {
      this.root.dispose();
    }
  },

  methods: {
    zoomToCountry(country) {
      const dataItem = this.worldSeries.getDataItemById(country);
      if (!dataItem) {
        return;
      }
      this.worldSeries.zoomToDataItem(dataItem);
    },

    colorCountries(selected = "") {
      this.worldSeries.mapPolygons.each(x => {
        const currIso = x.dataItem.dataContext.id;

        if (currIso === selected) {
          x.states.apply("selected");
          if (this.selected_country_risk_level) {
            x.states.apply(this.selected_country_risk_level);
          }
          return;
        }
        const idx = this.highlightedCountries.findIndex(hc => hc.iso === currIso);
        if (idx >= 0) {
          x.states.apply("highlighted");
          return;
        }

        x.states.apply("default");
      });
    },
  },
};
</script>
